import React from 'react';
import Layout from '../../components/layout';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

const Page = () => (
  <Layout>
    <p className="backlink">
      <Link to="/projects">← projects</Link>
    </p>
    <main>
      <h1>Helping Hands Training Portal</h1>
      <section>
        <p>
          A tailor-made online application for the NC Collaborative and NC
          Families United. Provides a custom venue for creating and distributing
          training content and assessments pursuant to the Collaborative’s
          strategic goal to “increase awareness and understanding of System of
          Care impact … and provide educational programs to enhance Systems of
          Care.”
        </p>
        {/* <p>
          live:{' '}
          <a href="https://helpinghands.nccollaborative.org">
            <code>helpinghands.nccollaborative.org</code>
          </a>
        </p>
        <p>
          source:{' '}
          <a href="https://github.com/nc-collaborative/training-portal">
            <code>github.com/nc-collaborative/training-portal</code>
          </a>
        </p> */}

        <p style={{ textAlign: 'center' }}>
          <em>more details coming soon!</em>
        </p>

        <hr />

        <p>
          <em>Technologies:</em> Node.js, TypeScript, Koa, TypeORM, nunjucks,
          SurveyJS
        </p>
      </section>
    </main>
  </Layout>
);

export default Page;
